<template>
  <div>

    <el-card style="min-height: 45rem" :body-style="{padding:'5px 20px 20px'}">
      <el-tabs>
        <el-tab-pane label="基础信息">
          <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
            <el-form label-position="left">
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-form-item label="排序">
                    <el-input-number v-model="form.sort"></el-input-number>
                  </el-form-item>
                  <el-form-item label="缩略图" class="flex-def">
                    <y_upload_img v-model="form.pic"></y_upload_img>
                  </el-form-item>
                  <el-form-item label="商品详情">
                    <br>
                    <y_editor v-model="form.detail"></y_editor>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="商品名称">
                    <el-input v-model="form.title"></el-input>
                  </el-form-item>
                  <el-form-item label="支付">
                    <el-input-number v-model="form.points"></el-input-number>
                    积分
                    +
                    <el-input-number v-model="form.fee"></el-input-number>
                    元
                  </el-form-item>
                  <el-form-item label="库存">
                    <el-input-number v-model="form.stock"></el-input-number>
                  </el-form-item>
                  <el-form-item label="划线价">
                    <el-input-number v-model="form.original_price"></el-input-number>
                    元
                  </el-form-item>
                  <el-form-item label="运费模版">
                    <y_choose_shipping v-model="form.shipping_id"></y_choose_shipping>
                  </el-form-item>
                  <el-form-item label="上架">
                    <el-switch v-model="form.status"></el-switch>
                  </el-form-item>
                  <el-form-item align="right">
                    <el-button @click="edit" type="primary">保存</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="轮播图">
          <el-table :data="bannerShowList">
            <el-table-column label="图片">
              <template v-slot="s">
                <img :src="s.row | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem">
              </template>
            </el-table-column>
            <el-table-column>
              <template v-slot:header>
                  <el-button @click="bannerAdd" type="primary">添加图片</el-button>
                <el-button @click="edit" type="primary">保存提交</el-button>
              </template>
              <template v-slot="s">
                <el-button @click="bannerDel(s.$index)" type="danger">移除图片</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <y_upload_img :show-input="false" ref="uploadBanner"></y_upload_img>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_choose_shipping from "@/components/shop/y_choose_shipping";

export default {
  name: "edit",
  components: {
    y_choose_shipping,
    y_upload_img,
    y_editor
  },
  data() {
    return {
      form: {
        banner: "",

        pic: "",
        detail: "",

        sort: 0,
        title: "",
        points: 0,
        fee: 0,
        stock: 0,
        original_price: 0,

        status: true,
        type: 1,
        shipping_id: 0,
      },
      edit_id: 0,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  computed:{
    bannerShowList(){
      if(!this.form.banner) return [];
      return this.form.banner.split(",");
    }
  },
  methods: {
    bannerDel(index){
      let o = [...this.bannerShowList];
      if (o.length <= 0)return;
      o.splice(index,1);
      this.form.banner = o.join(",");
    },
    bannerAdd(){
      this.$refs.uploadBanner.showUpload(e=>{
        let o = [...this.bannerShowList];
        o.unshift(e);
        this.form.banner = o.join(",");
      })
    },
    load() {
      this.$u.api.shop.plugin.pointsMall.goodsDetail({id: this.edit_id}).then(res => {
        this.form = res.goods;
      })
    },
    edit() {
      this.$u.api.shop.plugin.pointsMall.goodsEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
  }
}
</script>

<style scoped>

</style>